import styled from 'styled-components';
import { theme } from 'styles/styled';

const CustomPageLayout = styled.main`
  font-family: 'Roboto';
  min-height: calc(100vh - 160px - 60px - 228px);

  max-width: 1020px;
  margin: 120px auto 60px auto;
  padding: ${theme.dimensions.spacingBase16 * 1.5}px;
  padding-bottom: ${theme.dimensions.spacingBase16 * 3}px;

  @media ${theme.devices.medium} {
    margin-top: 160px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  h1 {
    font-size: ${theme.dimensions.spacingBase16 * 2}px;
    margin: 0 0 ${theme.dimensions.spacingBase16 * 2}px 0;
  }

  h2 {
    font-size: ${theme.dimensions.spacingBase16 * 1.75}px;
    margin: ${theme.dimensions.spacingBase16 * 1.5}px 0;
  }

  h3 {
    font-size: ${theme.dimensions.spacingBase16 * 1.75}px;
    margin: ${theme.dimensions.spacingBase16 * 1.25}px 0;
  }

  h4 {
    font-size: ${theme.dimensions.spacingBase16 * 1.5}px;
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  h5 {
    font-size: ${theme.dimensions.spacingBase16 * 1.25}px;
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  h6 {
    font-size: ${theme.dimensions.spacingBase16}px;
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  @media ${theme.devices.medium} {
    h1 {
      font-size: ${theme.dimensions.spacingBase16 * 2.5}px;
      margin: 0 0 ${theme.dimensions.spacingBase16 * 3}px 0;
    }

    h2 {
      font-size: ${theme.dimensions.spacingBase16 * 2}px;
    }

    h3 {
      font-size: ${theme.dimensions.spacingBase16 * 1.5}px;
    }

    h4 {
      font-size: ${theme.dimensions.spacingBase16 * 1.25}px;
    }
  }

  p {
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  strong {
    font-size: ${theme.dimensions.spacingBase16}px;
  }

  ol,
  ul {
    list-style: initial;
    padding-left: 40px;
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  li {
    padding: 6px 0;
    line-height: 24px;
  }

  pre {
    font-family: monospace;
    font-size: ${theme.dimensions.spacingBase16}px;
    max-width: 100%;
    white-space: break-spaces;
  }

  blockquote {
    font-style: italic;
  }

  .blocks-gallery-grid {
    list-style: none;
    padding: 0;
  }
`;

export default CustomPageLayout;
