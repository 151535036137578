import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  TranslationsContext,
  TranslationResult,
  translationsFactory,
} from 'settings/i18n';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import EducationNavigation from 'components/EducationNavigation/EducationNavigation';
import Footer from 'components/Footer/Footer';
import CustomPageLayout from 'components/CustomPageLayout/CustomPageLayout';

const CustomPageTemplate = ({
  data,
  pageContext: { content, seo },
}: {
  data: TranslationResult;
} & PageProps<{}, { content: string } & SEOMeta>) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data.homepage?.translation,
      },
    },
  });

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={{ seo }} />
      <ClientOnly>
        <EducationNavigation />
        <CustomPageLayout
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default CustomPageTemplate;

export const query = graphql`
  query($locale: String!) {
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...FooterTranslation
      ...HeaderTranslation
    }
  }
`;
